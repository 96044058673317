import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router';

import vuetify from './plugins/vuetify';

Vue.use(VueRouter);

// Componets for the router
import Portfolio from './components/portfolio/Portfolio.vue';

// Configure paths
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: Portfolio
    },
    // {
          // path: '',
          // component: ''
    // }
  ]
})
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
