<template>
  <v-container id="about">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <h2 class="w3-bottombar tron">About me</h2>
        <div>
          <p>
            I am a Computer & Software Systems Engineer who loves a
            good challenge and of course, writing great code. My passion for
            software development and eagerness to learn new skills has allowed
            me to gain experience in a variety of technologies and programming
            languages.
          </p>
          <p>
            I have full-stack web development experience working with HTML5,
            CSS3, JavaScript (Vue JS, JQuery, Node JS, REST APIs). PHP, Python,
            C, C# and Java are also under my belt. Moreover, I am currently
            working on new projects exploring AWS services aiming for getting
            AWS accreditations to successfully work in the cloud.
          </p>
        </div>
      </v-col>

      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
};
</script>
      