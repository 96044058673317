<template>
  <v-container id="projects" class="container">
    <h2 class="tron w3-bottombar my-5">Projects</h2> 
    <v-row justify="space-around">
        <ProjectCard v-for="project in projects" :key="project.title" :project="project" />
    </v-row>
  </v-container>
</template>

<script>
import labodeguitadel23_image from "../../assets/projects/labodeguitadel23-958x937.png";
import oceanforestfarm_image from "../../assets/projects/ocean-forest-farm-958x937.png";
import soccerworld_image from "../../assets/projects/soccerworld-958x937.png";
import andinaoutdoors_image from "../../assets/projects/andina-outdoors-958x937.png";
import andinaoutdoors_de_image from "../../assets/projects/andina-outdoors-de-958x937.png";
import andinaoutdoors_cl_image from "../../assets/projects/andina-outdoors-cl-958x937.png";
import owen_pacific_image from "../../assets/projects/owen-pacific-958x937.png"
import tca_partners_image from "../../assets/projects/tca-partners-958x937.png"
import foodie_image from "../../assets/projects/foodie-958x937.png";
import ProjectCard from "./ProjectCard.vue";

export default {
 data: () => ({
      projects: [
        {
          title: "Ocean Forest Farm",
          url: "www.oceanforestfarm.com.au",
          description: "An subscription-based e-comerce website built on Shopify",
          preview_image: oceanforestfarm_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "Soccer World (AUS)",
          url: "www.soccerworld.com.au",
          description: "An e-comerce website built on Shopify",
          preview_image: soccerworld_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "Andina Outdoors (Global)",
          url: "www.andinaoutdoors.com",
          description: "An e-comerce website built on Shopify",
          preview_image: andinaoutdoors_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "La Bodeguita del #23 (PER)",
          url: "www.labodeguitadel23.com",
          description: "An e-comerce website built on Shopify",
          preview_image: labodeguitadel23_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "Andina Outdoors (GER)",
          url: "www.andinaoudtoors.de",
          description: "An e-comerce website built on Shopify",
          preview_image: andinaoutdoors_de_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "Andina Outdoors (Chile)",
          url: "www.andinaoutdoors.cl",
          description: "An e-comerce website built on Shopify",
          preview_image: andinaoutdoors_cl_image,
          tags: [ 'Shopify', 'E-commerce' ]
        },
        {
          title: "Foodie",
          url: "#",
          description:
            "An API mush-up using Zomato API, Flickr API and Google Maps API. Built as a server side application using Node JS and Express JS",
          preview_image: foodie_image,
          tags: [ 'Node.js', 'AWS']
        },
        {
          title: "TCA Partners (AUS)",
          url: "www.tca-partners.com",
          description: "A custom wordpress theme built.",
          preview_image: tca_partners_image,
          tags: [ 'Wordpress' ]
        },
        {
          title: "Owen Pacific Workforce (AUS)",
          url: "www.owenpacific.com",
          description: "A custom wordpress theme built.",
          preview_image: owen_pacific_image,
          tags: [ 'Wordpress' ]
        }
      ]
 }),
 components: {
     ProjectCard
 }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .w3-button {
    display: inline-block;
    font-size: 12px !important;
  }
}
</style>