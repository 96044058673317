<template>
  <v-app>
    <AppBar />  
    <v-main class="tron">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar';

export default {
  name: 'App',

  components: {
    AppBar
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
