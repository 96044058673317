import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=49aa024a&scoped=true&"
import script from "./Projects.vue?vue&type=script&lang=js&"
export * from "./Projects.vue?vue&type=script&lang=js&"
import style0 from "./Projects.vue?vue&type=style&index=0&id=49aa024a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49aa024a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})
