<template>
  <v-app-bar
    app
    dark
    color="rgb(0,0,0,0.3)"
    hide-on-scroll
  >
    <div class="d-flex align-center">
      <v-img
        alt="GaBoGz"
        class="shrink mr-2"
        contain
        src="@/assets/favicon3.png"
        transition="scale-transition"
        width="40"
      />
    </div>
    <v-slide-group multiple>
      <v-slide-item v-for="(item, index) in navigation" :key="index">
        <v-btn text :href="'/#' + item.href">
          {{ item.title }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>

    <v-spacer></v-spacer>

    <!-- <v-slide-item> -->
    <v-btn class="d-xs-none" icon :href="socials.facebook.url" target="_blank">
      <v-icon>{{ socials.facebook.icon }}</v-icon>
    </v-btn>
    <!-- </v-slide-item> -->
    <!-- <v-slide-item> -->
    <v-btn class="d-xs-none" icon :href="socials.instagram.url" target="_blank">
      <v-icon>{{ socials.instagram.icon }}</v-icon>
    </v-btn>
    <!-- </v-slide-item> -->

    <!-- <v-slide-item> -->
    <v-btn class="d-xs-none" icon :href="socials.linkedin.url" target="_blank">
      <v-icon>{{ socials.linkedin.icon }}</v-icon>
    </v-btn>
    <!-- </v-slide-item> -->
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    model: 0,
    navigation: [
      { title: "Home", href: "home", icon: "fa fa-home" },
      { title: "About", href: "about", icon: "fa fa-user" },
      { title: "Projects", href: "projects", icon: "fa fa-eye" },
      { title: "Contact", href: "contact", icon: "fa fa-envelope" },
    ],
    socials: {
      facebook: {
        url: "https://www.facebook.com/GaboGz",
        icon: "mdi-facebook",
      },
      instagram: {
        url: "https://instagram.com/gabogz",
        icon: "mdi-instagram",
      },
      linkedin: {
        url: "https://www.linkedin.com/in/gabriel-garate-zea/",
        icon: "mdi-linkedin",
      },
    },
  }),
};
</script>

<style scoped>
.social_icon {
  cursor: pointer;
  text-decoration: none;
}
</style>