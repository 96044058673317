<template>
  <v-container>
    <h2 class="tron w3-bottombar">Accreditations</h2>

    <v-row 
      class="my-4 pa-2" 
      v-for="(accreditation, index) in accreditations" 
      :key="index"
      justify="start"
      gap=""
      >
        <v-col cols="12" sm="2">
          <v-img
            dark
            width="95"
            height="95"
            :contain="true"
            :aspect-ratio="1"
            :alt="accreditation.title"
            :src="accreditation.logo"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="10">
          <h4>{{ accreditation.title }}</h4>
          <div>{{ accreditation.issued_by }}</div>
          <div>{{ accreditation.date_issued }}</div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import scrum_logo from "../../assets/psd-1.svg";
import shopify_logo from "../../assets/shopify_glyph.png";

export default {
  name: "Accreditations",
  data() {
    return {
      accreditations: [
        {
          title: "Professional Scrum Developer I",
          issued_by: "Scrum.org",
          date_issued: "Aug 31, 2023",
          logo: scrum_logo
        },
        {
          title: "Shopify - Theme Development",
          issued_by: "Shopify",
          date_issued: "Feb 23, 2021",
          logo: shopify_logo
        },
      ],
    };
  },
};
</script>
