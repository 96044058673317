<template>
  <v-container>
    <h2 class="w3-bottombar">My Skills</h2>

    <v-chip 
      v-for="skill in skills" :key="skill" 
      class="ma-2"
      color="teal lighten-4"
      outlined
      >{{ skill }}</v-chip>

      <v-row class="my-4 ml-2">
        <v-btn
          color="teal lighten-4"
          tile
          href="https://drive.google.com/file/d/1wovP1w3rqHgzx6_tLF7KfYSQszlGbbDI/view?usp=drive_link"
        >
          <v-icon class="mr-2">mdi-cloud-download</v-icon> Download Resume
        </v-btn>
      </v-row>
      <!-- </span> -->
    <!-- </div> -->
  </v-container>
</template>

<script>
export default {
  name: "Skills",
  data() {
    return {
      skills: [
        "Containerisation",
        "Docker",
        "Docker Compose",
        "CI/CD Pipelines",
        "Azure DevOps",
        "Fullstack Development",
        "Javascript",
        "Vue.js",
        "Node.js",
        "Express",
        "REST APIs",
        "Shopify",
        "Shopify Theme Development",
        "Liquid",
        "Shopify Ajax API",
        "Shopify CLI",
        "Wordpress",
        "Amazon Web Services (AWS)",
        "AWS CLI",
        "EC2",
        "S3",
        "Route 53",
        "Amplify",
        "Load Balancing",
        "Auto Scaling Groups",
        "Hosting",
        "Bluehost",
        "CloudFlare",
        "Python",
        "Java",
        "C",
        "C#",
        "PHP",
        "Vend"
      ],
      resume_url: 'https://drive.google.com/file/d/1wovP1w3rqHgzx6_tLF7KfYSQszlGbbDI/view?usp=drive_link'
    };
  },
};
</script>
