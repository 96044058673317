<template>
      <!-- Footer -->
      <footer class="w3-col s12 w3-padding-64 tron w3-xlarge w3-center">
          
          <div class="w3-center">
            <v-btn dark icon v-for="(link, index) in social_links" :key="index" 
            class="mx-2"
            :href="link.url">
              <v-icon>{{ link.icon }}</v-icon>
            </v-btn>
          </div>
          <div class="w3-center">
            <v-btn dark text class=" my-2" href="#banner">
              <v-icon color="#5CE1E6">mdi-code-braces</v-icon>
              GaboGz 
            </v-btn>
          </div>
      
      </footer>
      <!-- End footer -->
</template>

<script>
export default {
    name: "Footer" ,
    data() {
      return {
        social_links: [
          {
            icon: 'mdi-facebook',
            url: 'https://facebook.com/gabogz'
          },
          { 
            icon: 'mdi-instagram',
            url: 'https://www.instagram.com/gabogz'
          },
          {
            icon: 'mdi-linkedin',
            url: 'https://www.linkedin.com/in/gabriel-garate-zea/'  
          },
          {
            icon: 'mdi-github',
            url: 'https://github.com/GaboGZ'  
          },
        ]
      }
    }
}
</script>