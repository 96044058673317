<template>
  <v-container id="contact">
    <h2 class="tron w3-bottombar">How to reach me</h2>

    <div class="w3-section w3-col s12 m6">
      <p>
        <v-icon color="teal lighten-4" class="mr-2">mdi-google-maps</v-icon>
        Brisbane, QLD, Australia
      </p>
      <p>
        <v-icon color="teal lighten-4" class="mr-2">mdi-email</v-icon>
        gabriel.garate.zea@gmail.com
      </p>
    </div>

    <div v-if="!form_submitted" class="w3-section w3-col s12 m6">
      <p>Let's get in touch.</p>

      <form class="contact-form" @submit.prevent="sendEmail">
        <p>
          <input
            class="w3-input w3-padding-16 tron"
            type="text"
            name="name"
            v-model="name"
            placeholder="Name"
            required
          />
        </p>
        <p>
          <input
            class="w3-input w3-padding-16 tron"
            type="email"
            name="email"
            v-model="email"
            placeholder="Email"
            required
          />
        </p>
        <p>
          <textarea
            class="w3-input w3-padding-16 tron"
            type="text"
            name="message"
            v-model="message"
            rows="5"
            placeholder="Your message..."
            required
          />
        </p>
        <p>
          <v-btn
            type="submit"
            color="teal lighten-4"
            tile
          >
            <i class="fa fa-paper-plane mr-2"></i> Send Message
          </v-btn>
        </p>
      </form>
    </div>

    <div v-if="form_submitted" class="w3-section w3-col s12 m6 w3-center">
      <p>
        Thank you. I'll be in touch.<br />
        Have a great day!
      </p>
    </div>
  </v-container>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      form_submitted: false,
    };
  },
  methods: {
    sendEmail(e) {
      try {
        let params = {
          name: this.name,
          email: this.email,
          message: this.message,
        };
        console.log(params, e.target);

        emailjs
          .sendForm(
            "service_rucqd84",
            "contact_form",
            e.target,
            "user_tuF3jswpLw41gOTIwohs8"
          )
          .then((response) => {
            console.log("Email sent");
            console.log(response.status);
            console.log(response.text);
            this.form_submitted = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>