<template>
  <div id="banner" class="banner">
      <div class="media-wrapper">
        <video ref="video" src="https://cdn.shopify.com/videos/c/o/v/e4547fa5e64941529d0d126aa0733ef0.mp4" playsinline autoplay muted loop></video>
      </div>
      <div class="banner-content">
        <div class="banner-inner">
          <h1 class="header-title">
            <span class="">I'm</span> Gabriel Gárate.
          </h1>
          <p class="header-subtitle">Software Engineer / Web Developer</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menu: [
        { title: "Home", icon: "fa fa-home" },
        { title: "About", icon: "fa fa-user" },
        { title: "Projects", icon: "fa fa-eye" },
        { title: "Contact", icon: "fa fa-envelope" },
      ]
    };
  },
  filters: {
    lowerCase: function (value) {
      return value.toLowerCase();
    }
  }
};
</script>

<style scoped>

.banner {
  position: relative;
  top: -64px;
}

.media-wrapper {
  position: relative;
  min-height: 375px;
  height: 375px;
  transition: all .3s ease;
}
.media-wrapper:after {
  position: absolute;
  inset: 0;
  content: "";
  z-index: 1;
  background-color: rgba(0,0,0,0.3);
  background: rgb(0,0,0);
  background: linear-gradient(0deg, var(--colors-tron-dark) 5%, rgba(0,0,0,0) 100%);
}
.media-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  inset:0;
  z-index: 2;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-inner {
  text-align: center;
  z-index: 2;
}

.header-title, .header-subtitle {
  text-shadow: 0px 0px 8px var(--colors-tron-accent-lighter);
  transition: all .3s ease;
}

.header-subtitle {
  font-size: 1.5rem;
}

@media (min-width: 680px) {
  .media-wrapper { 
    height: 50vh;
  }
  .header-title {
    font-size: 3.5rem;
  } 
  .header-subtitle {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  .header-title {
    font-size: 5rem;
  } 
  .header-subtitle {
    font-size: 2.25rem;
  }
}
</style>