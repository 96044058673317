<template>
  <div>
    <Header />
    <About />
    <Skills />
    <Accreditations />
    <Projects />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import About from "./About.vue";
import Skills from "./Skills.vue";
import Projects from "./Projects";
import Accreditations from "./Accreditations.vue";
import Contact from "./Contact";
import Footer from "./Footer";

export default {
  name: "Portfolio",
  data: () => ({}),
  components: {
    Header,
    About,
    Skills,
    Projects,
    Accreditations,
    Contact,
    Footer
  },
};
</script>
