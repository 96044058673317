<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <div class="w3-padding w3-bottombar w3-border-white w3-center w3-large">
      {{ project.title }}
    </div>
    <v-card tile dark width="auto">
      <v-img
        dark
        height="auto"
        :contain="false"
        :aspect-ratio="1"
        :alt="project.title"
        :src="project.preview_image"
        gradient="to bottom, rgba(200,200,200,0.1), rgba(10,10,10,0.75)"
      ></v-img>
      <v-card-actions style="margin-top: -52px;" >
        <v-btn
          small
          dark
          depressed
          class="ml-2"
          color="rgba(250,250,250,0.2)"
          :href="'//' + project.url"
          target="_blank"
          >View</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          fab
          x-small
          dark
          depressed
          class="mr-2"
          color="rgba(250,250,250,0.2)"
          @click="show_description = !show_description"
        >
          <v-icon>{{
            show_description ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>

        <div v-if="show_description" class="tron">
          <v-card-text>{{ project.description }}</v-card-text>
          <v-chip-group column>
            <v-chip 
              outlined 
              class="ma-2"
              color="teal lighten-4"
              small
              v-for="tag in project.tags" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-expand-transition>
    </v-card>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    show_description: true,
  }),
  props: ["project"],
};
</script>
